#social:hover .socialIcon{
  transform: scale(1.05);
}

#social .socialIcon{
  transition: all ease-in-out .3s
}

#card:hover #img{
  transform: scale(1.1);
}

#card #img{
  transition: all ease-in-out .3s;
}

#card:hover #card_btn{
  transform: scale(1.05);
}

