.indicator {
  cursor: pointer;
  padding: 10px;
  text-align: center;
  border: 5px #666 solid;
  margin: 0;
}

.indicator.active {
  color: #000;
  background: #666;
}